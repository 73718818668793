import React from "react";
import { useState, useEffect, useRef } from "react";
import axios from "axios";
import { baseUrl } from "../utils/script";
import emailjs from "@emailjs/browser";
import { Navigate, useNavigate } from "react-router-dom";
// import axios from "axios";

const Signup = () => {
  const [input, setInput] = useState({ role: 1 });

  const nav = useNavigate();

  const form = useRef();

  // useEffect(() => {

  // }, [])

  const submitChange = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_xpivxkh",
        "template_5vynkfr",
        form.current,
        "q8D6ygVMWszC_0U6s"
      )
      .then(
        (result) => {
          // alert(result.text);
          console.log(result.text);
          axios.post(baseUrl + "register", input).then((res) => {
            console.log(res.data);

            if (res.data.status === 1) {
              alert("Sucessfully registered");
              setInput({});
              nav("/index.html");
            } else {
              alert("Not sucessful");
            }
          });
        },
        (error) => {
          alert(error.text);
          console.log(error.text);
        }
      );
  };

  // const submitChange = () =>{
  //   console.log(input)
  //   // axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
  //   axios.post(baseUrl+'register',input)
  //     .then(function (response) {
  //       console.log(response.data);
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //     })
  // }

  const handleChange = (e) => {
    setInput({ ...input, [e.target.name]: e.target.value });
  };

  return (
    <div>
      <div className="addprods">
        <h1 className="Heading">Sign Up</h1>
        <form ref={form} onSubmit={(e) => submitChange(e)}>
          <div className="form-control">
            <label htmlFor="name" id="label-name">
              Name
            </label>
            <input
              type="text"
              id="name"
              name="name"
              onChange={(e) => handleChange(e)}
              placeholder="Enter your name"
              required
            />
          </div>

          <div className="form-control">
            <label htmlFor="email" id="label-name">
              Email
            </label>
            <input
              type="text"
              id="email"
              name="email"
              onChange={(e) => handleChange(e)}
              placeholder="Enter your email"
              required
            />
          </div>

          <div className="form-control">
            <label htmlFor="dob" id="label-name">
              Date of Birth
            </label>
            <input
              type="date"
              id="dob"
              name="dob"
              onChange={(e) => handleChange(e)}
              placeholder="Enter your DOB"
              required
            />
          </div>

          <div className="form-control">
            <label htmlFor="phone" id="label-name">
              Phone
            </label>
            <input
              type="number"
              id="phone"
              name="phone"
              onChange={(e) => handleChange(e)}
              placeholder="Enter your phone"
              required
            />
          </div>

          <div className="form-control">
            <label htmlFor="address" id="label-name">
              Address
            </label>
            <input
              type="text"
              id="address"
              name="address"
              onChange={(e) => handleChange(e)}
              placeholder="Enter your Address"
              required
            />
          </div>

          <div className="form-control">
            <label htmlFor="password" id="label-name">
              Password
            </label>
            <input
              type="password"
              id="password"
              name="password"
              onChange={(e) => handleChange(e)}
              placeholder="Enter your password"
              required
            />
          </div>

          <div className="form-control">
            <label htmlFor="confirm-password" id="label-name">
              Confirm Password
            </label>
            <input
              type="password"
              id="confirm-password"
              placeholder="Reenter your password"
            />
          </div>

          <button className="adsubmit" type="submit" value="submit">
            {/* <a href="index.html" look="flat"> */}
            Sign Up
            {/* </a> */}
          </button>
        </form>
        <div className="login-signup">
          <span className="stext">
            Already Sign Up?
            <a href="index.html" className="text login-link">
              {" "}
              Login Now
            </a>
          </span>
        </div>
      </div>
    </div>
  );
};

export default Signup;
