import React from "react";
import "../styles/general.css";
import "../styles/queries.css";
import "../styles/style.css";
import "../styles/about.css";
import img2 from "../assets/img/customers/hannah.jpg";
import Header from "./header";

const SchoolAdmin = () => {
  return (
    <div>
      <Header />
      <main className="full-page">
        <section className="section-hero">
          <div className="hero">
            <div className="hero-text-box">
              <h1 className="heading-primary">School Admin</h1>
              <p className="hero-description">Admin fot UTA.</p>
              <div className="profile-background-color">
                <a
                  href="managestudents.html"
                  className="btn btn--full margin-right-sm"
                >
                  Manage Student
                </a>
              </div>
              <div className="profile-background-color">
                <a
                  href="managebusinessowner.html"
                  className="btn btn--full margin-right-sm"
                >
                  Manage Business Owner
                </a>
              </div>
              <div className="profile-background-color">
                <a
                  href="addclub.html"
                  className="btn btn--full margin-right-sm"
                >
                  Create Club
                </a>
                <a
                  href="deleteclub.html"
                  className="btn btn--full margin-right-sm"
                >
                  Delete Club
                </a>
              </div>
              <div className="profile-background-color">
                <a href="deleteadvertisement.html" className="btn btn--full">
                  Delete Advertisement
                </a>
              </div>
            </div>
            <div className="hero-img-box">
              <picture>
                {/* <source srcset="img/customers/hannah.jpg" type="image/webp">
             <source srcset="img/customers/hannah.jpg" type="image/png"> */}
                <img src={img2} className="hero-img" alt="Cover Photo" />
              </picture>
            </div>
          </div>
        </section>
      </main>
      <footer className="footer">
        <div className="container grid grid--footer">
          <p className="copyright">
            Copyright &copy; <span className="year">2027</span> by University
            Marketplace, Inc. All rights reserved.
          </p>
        </div>
      </footer>
    </div>
  );
};

export default SchoolAdmin;
