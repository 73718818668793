import React from "react";

import "../styles/general.css";
import "../styles/queries.css";
import "../styles/style.css";
import "../styles/about.css";
import Header from "./header";

const Services = () => {
  return (
    <div>
      <Header />
      <main>
        <section className="section-hero">
          <div className="hero">
            <div className="hero-text-box">
              <h1 className="heading-primary">Buy / Sell Items</h1>
              <p className="hero-description">
                A place where you get your daily usage things in resonable price
                and high quality product that make your life as easy as straight
                line.
              </p>
            </div>
          </div>
        </section>

        <section className="section-hero">
          <div className="hero">
            <div className="hero-text-box">
              <h1 className="heading-primary">Blogs</h1>
              <p className="hero-description">
                Connect with us on our Blogging Platform.
              </p>
            </div>
          </div>
        </section>
        <section className="section-hero">
          <div className="hero">
            <div className="hero-text-box">
              <h1 className="heading-primary">Clubs</h1>
              <p className="hero-description">
                Create and Join clubs as easy as 1, 2, 3.
              </p>
            </div>
          </div>
        </section>
      </main>
      <footer className="footer">
        <div className="container grid grid--footer">
          <p className="copyright">
            Copyright &copy; <span className="year">2027</span> by University
            Marketplace, Inc. All rights reserved.
          </p>
        </div>
      </footer>
    </div>
  );
};

export default Services;
