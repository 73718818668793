import React from "react";
import "../styles/general.css";
import "../styles/queries.css";
import "../styles/style.css";
import "../styles/about.css";
import "../styles/login.css";
import Header from "./header";

const ContactUs = () => {
  return (
    <div>
      <Header />
      <main>
        <div className="addprods">
          <h1 className="Heading">Contact Us</h1>
          <div className="form-control">
            <label htmlFor="name" id="label-name">
              {" "}
              Name{" "}
            </label>
            <input type="text" id="name" placeholder="Enter name" />
          </div>
          <div className="form-control">
            <label htmlFor="name" id="label-name">
              {" "}
              Email{" "}
            </label>
            <input type="text" id="name" placeholder="Enter email" />
          </div>
          <div className="form-control">
            <label htmlFor="name" id="label-name">
              {" "}
              Query{" "}
            </label>
            <textarea
              type="text"
              id="name"
              placeholder="Enter your query here"
            ></textarea>
          </div>

          <button className="adsubmit" type="submit" value="submit">
            Submit
          </button>
        </div>

        <div className="about-layout">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d6712.84398861627!2d-97.1139366!3d32.7279836!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x864e7d6dabc9564d%3A0x6c5cbeb084c8b76a!2sUniversity%20of%20Texas%20at%20Arlington!5e0!3m2!1sen!2sus!4v1664929341549!5m2!1sen!2sus"
            width="600"
            height="450"
            style={{ border: 0 }}
            allowFullCcreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </main>
      <footer className="footer">
        <div className="container grid grid--footer">
          <p className="copyright">
            Copyright &copy; <span className="year">2027</span> by University
            Marketplace, Inc. All rights reserved.
          </p>
        </div>
      </footer>
    </div>
  );
};

export default ContactUs;
