import React from "react";
import "../styles/general.css";
import "../styles/queries.css";
import "../styles/style.css";
import "../styles/about.css";

import img2 from "../assets/img/customers/ben.jpg";
import Header from "./header";

const BusinessAdmin = () => {
  return (
    <div>
      <Header />
      <main className="full-page">
        <section className="section-hero">
          <div className="hero">
            <div className="hero-text-box">
              <h1 className="heading-primary">Business Owner</h1>
              <p className="hero-description">
                A Business owner at UTA selling products.
              </p>
              <div className="profile-background-color">
                <a href="addproduct.html" className="btn btn--full margin-right-sm">
                  Add Products
                </a>
                <a href="deleteproduts.html" className="btn btn--full">
                  Delete Products
                </a>
              </div>
              <div className="profile-background-color">
                <a href="addadvertisement.html" className="btn btn--full margin-right-sm">
                  Post Advertisement
                </a>
              </div>
              <div className="profile-background-color">
                <a href="deleteadvertisement.html" className="btn btn--full">
                  Delete Advertisement
                </a>
              </div>
            </div>
            <div className="hero-img-box">
              <picture>
                {/* <source srcset="img/customers/ben.jpg" type="image/webp">
                  <source srcset="img/customers/ben.jpg" type="image/png">
       */}
                <img src={img2} className="hero-img" alt="Cover Photo" />
              </picture>
            </div>
          </div>
        </section>
      </main>
      <footer className="footer">
        <div className="container grid grid--footer">
          <p className="copyright">
            Copyright &copy; <span className="year">2027</span> by University
            Marketplace, Inc. All rights reserved.
          </p>
        </div>
      </footer>
    </div>
  );
};

export default BusinessAdmin;
