import React from "react";
import { IonIcon } from "@ionic/react";
import '../styles/style.css'
import "../styles/general.css";
import "../styles/queries.css";
import "../styles/about.css";

import "../styles/login.css";
import "../styles/prodlist.css";
import "../styles/search.css";


const Header = () => {
  const role = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user")).role
    : 0;

  return (
    <header className="header">
      <a href="home.html">
        <h1 className="title">University Marketplace</h1>
      </a>
      <nav className="main-nav">
        <ul className="main-nav-list">
          <li>
            <a className="main-nav-link" href="home.html">
              Home
            </a>
          </li>
          <li>
            <a className="main-nav-link" href="aboutus.html">
              About
            </a>
          </li>
          <li>
            <a className="main-nav-link" href="services.html">
              Service
            </a>
          </li>
          <li>
            <a className="main-nav-link" href="contactus.html">
              Contact
            </a>
          </li>

          {!localStorage.getItem("user") ? (
            <>
              <li>
                <a className="main-nav-link" href="index.html">
                  Login
                </a>
              </li>
              <li>
                <a className="main-nav-link" href="Signup.html">
                  Signup
                </a>
              </li>
            </>
          ) : (
            <>
              <li>
                <a
                  className="main-nav-link"
                  href="login.html"
                  onClick={() => localStorage.removeItem("user")}
                >
                  Logout
                </a>
              </li>
            </>
          )}

          <li>
            <a className="main-nav-link" href="https://mxs3288.uta.cloud/blog/">
              Blog
            </a>
          </li>
          <li>
            <a
              className="main-nav-link nav-cta"
              href={
                parseInt(role) === 1
                  ? "profile.html"
                  : parseInt(role) === 2
                  ? "businessadmin.html"
                  : parseInt(role) === 3
                  ? "schooladmin.html"
                  : parseInt(role) === 4
                  ? "superadmin.html"
                  : parseInt(role) === 0
                  ? "home.html"
                  : "home.html"
              }
            >
              Profile
            </a>
          </li>
        </ul>
      </nav>
      <button className="btn-mobile-navs">
        <IonIcon
          className="icon-mobile-nav"
          icon="menuOutline"
          name="menu-outline"
        ></IonIcon>
        <IonIcon
          className="icon-mobile-nav"
          icon="closeOutline"
          name="close-outline"
        ></IonIcon>
      </button>
    </header>
  );
};

export default Header;
