import React, { useState, useEffect } from "react";
import "../styles/general.css";
import "../styles/queries.css";
import "../styles/style.css";
import "../styles/prodlist.css";
import "../styles/search.css";
import "../styles/login.css";
import "../styles/cart.css";
import Header from "./header";
import axios from "axios";
import { baseUrl } from "../utils/script";

const Orders = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    let id = JSON.parse(localStorage.getItem("user")).id;
    axios
      .get(baseUrl + `viewsolditems/${id}`)
      .then(function (response) {
        console.log(response.data);
        if (response.data)
          if (response.data.length > 0) setData(response.data);
          else setData([]);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const returnProuct = (user) => {
    axios
      .get(baseUrl + `returnproducts/${user.id}`)
      .then(function (response) {
        console.log(response.data);
        //   if (response.data) if (response.data.length > 0) setData(response.data);
        getData();
        alert("Product returned successfully");
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <div>
      <div className="addprod">
        <Header />
        <h1 className="Heading">Previous Orders</h1>
        <div className="gallerys">
          {data.length > 0 ? (
            data.map((user, index) => (
              <>
                <div className="cards">
                  <img className="displaypicture" alt="" src={user.image} />
                  <h3 className="prod">{user.name}</h3>
                  <p className="description">{user.description}</p>
                  <h6>${user.price}</h6>
                  <button className="buy" onClick={() => returnProuct(user)}>
                    {/* <a className="no-underline" href="cart.html"> */}
                    Return Product
                    {/* </a> */}
                  </button>
                </div>
              </>
            ))
          ) : (
            <div className="no-items">No items in the previous order</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Orders;
