import React, { useState, useEffect } from "react";
import "../styles/general.css";
import "../styles/queries.css";
import "../styles/style.css";
import "../styles/prodlist.css";
import "../styles/search.css";
import "../styles/login.css";
import "../styles/cart.css";
import Header from "./header";
import axios from "axios";
import { baseUrl } from "../utils/script";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const Reports = () => {
  const id = parseInt(JSON.parse(localStorage.getItem("user")).id);

  const [data1Advertisement, setData1] = useState([]);
  const [data2Product, setData2] = useState([]);
  const [data3Club, setData3] = useState([]);
  const [data4Student, setData4] = useState([]);
  const [data5BusinessOwner, setData5] = useState([]);
  const [data6SchoolAdmin, setData6] = useState([]);
  const [data7SuperUser, setData7] = useState([]);
  const [data8ProductsSold, setData8] = useState([]);
  const [totalValue, setTotalValue] = useState(0);

  const data1 = {
    labels: ["Student", "Business Owner", "School Admin", "Super User"],
    datasets: [
      {
        label: "Users Comparison",
        data: [
          data4Student.length,
          data5BusinessOwner.length,
          data6SchoolAdmin.length,
          data7SuperUser.length,
        ],
        backgroundColor: "rgba(230, 126, 34, 0.5)",
      },
    ],
  };

  const data2 = {
    labels: ["Total Available Products", "Products Sold"],
    datasets: [
      {
        label: "Products Available Vs. Products Sold",
        data: [data2Product.length, data8ProductsSold.length],
        backgroundColor: "rgba(230, 126, 34, 0.5)",
      },
    ],
  };

  const data3 = {
    labels: ["Total Clubs", "Total Advertisements"],
    datasets: [
      {
        label: "Total Clubs Vs. Total Advertisements",
        data: [data3Club.length, data1Advertisement.length],
        backgroundColor: "rgba(230, 126, 34, 0.5)",
      },
    ],
  };
  const options1 = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Total Users",
      },
    },
  };
  const options2 = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Products Comparison",
      },
    },
  };
  const options3 = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Clubs and Advertisements",
      },
    },
  };

  useEffect(() => {
    getData1();
    getData2();
    getData3();
    getData4();
    getData5();
    getData6();
    getData7();
    getData8();
  }, []);

  //   console.log(
  //     data1Advertisement,
  //     data2Product,
  //     data3Club,
  //     data4Student,
  //     data5BusinessOwner,
  //     data6SchoolAdmin,
  //     data7SuperUser,
  //     data8ProductsSold
  //   );

  const getData1 = () => {
    axios
      .get(baseUrl + `displayadvertisement`)
      .then(function (response) {
        if (response.data)
          if (response.data.length > 0) setData1(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const getData2 = () => {
    axios
      .get(baseUrl + `displayproduct`)
      .then(function (response) {
        if (response.data)
          if (response.data.length > 0) setData2(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const getData3 = () => {
    axios
      .get(baseUrl + `joinclub`)
      .then(function (response) {
        if (response.data)
          if (response.data.length > 0) setData3(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const getData4 = () => {
    axios
      .get(baseUrl + `managestudent/1`)
      .then(function (response) {
        if (response.data)
          if (response.data.length > 0) setData4(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const getData5 = () => {
    axios
      .get(baseUrl + `managestudent/2`)
      .then(function (response) {
        if (response.data)
          if (response.data.length > 0) setData5(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const getData6 = () => {
    axios
      .get(baseUrl + `managestudent/3`)
      .then(function (response) {
        if (response.data)
          if (response.data.length > 0) setData6(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const getData7 = () => {
    axios
      .get(baseUrl + `managestudent/4`)
      .then(function (response) {
        if (response.data)
          if (response.data.length > 0) setData7(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const getData8 = () => {
    axios
      .get(baseUrl + `reportviewsolditems`)
      .then(function (response) {
        if (response.data)
          if (response.data.length > 0) {
            let total = 0;
            console.log(response.data);
            response.data.map((ele, ind) => {
              total += ele.price;
              console.log(ele.price);
            });
            setTotalValue(total);
            setData8(response.data);
          }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  // reportviewsolditems

  return (
    <div>
      <Header />
      <div className="Heading">Reports</div>
      <div className="report-page-layout">
        <div className="report-box-layout">
          <div className="report-page-text">
            Total Number of Students : {data4Student.length}
          </div>
          <div className="report-page-text">
            Total Number of Business Owners : {data5BusinessOwner.length}
          </div>
          <div className="report-page-text">
            Total Number of School Admins: {data6SchoolAdmin.length}
          </div>
          <div className="report-page-text">
            Total Number of Super Users : {data7SuperUser.length}
          </div>
          <Bar options={options1} data={data1} />
        </div>
        <div className="report-box-layout">
          <div className="report-page-text">
            Total Number of Available Products : {data2Product.length}
          </div>
          <div className="report-page-text">
            Total Number of Products Sold : {data8ProductsSold.length}
          </div>
          <div className="report-page-text">
            Total Value of Products Sold : ${totalValue.toFixed(2)}
          </div>
          <Bar options={options2} data={data2} />
        </div>
        <div className="report-box-layout">
          <div className="report-page-text">
            Total Number of Clubs : {data3Club.length}
          </div>
          <div className="report-page-text">
            Total Number of Advertisements : {data1Advertisement.length}
          </div>
          <Bar options={options3} data={data3} />
        </div>
      </div>
    </div>
  );
};

export default Reports;
