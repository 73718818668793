import React ,{useState, useEffect} from "react";
import "../styles/general.css";
import "../styles/queries.css";
import "../styles/style.css";
import "../styles/prodlist.css";
import "../styles/search.css";
import "../styles/login.css";
import "../styles/cart.css";
import Header from "./header";
import axios from "axios";
import { baseUrl } from "../utils/script";

export const Cart = () => {
  const [data, setData] = useState([])

  useEffect(() => {
    getData();
  }, [])
  
  const getData = () =>{
    let id = JSON.parse(localStorage.getItem("user")).id;
    axios.get(baseUrl + `viewcart/${id}`)
    .then(function (response) {
     if(response.data) if(response.data.length > 0) setData(response.data);
    })
    .catch(function (error) {
      console.log(error);
    })
  }

  console.log(data);
  
  return (
    <div>
      {" "}
      <Header />
      <div className="shopping-cart">
        {/* <!-- Title --> */}
        <div className="Heading">Cart</div>

        {/* <!-- Product #1 --> */}
        { data.length> 0 ? data.map(user=>(
          <>
          <div className="item">
          <div className="buttons">
            <i className="fa fa-trash-o fa-2x"></i>
          </div>

          <div className="image">
            <img src={user.image} alt="" height="80px" width="120px" />
          </div>

          <div className="cart-description">
            <span>{user.name}</span>
          </div>

          <div className="total-price">${user.price}</div>
        </div>
          </>
        )) : <div className="no-items">No items in the Cart</div>}
        
        {data.length > 0 ? <>
        <div className="checkout-layout">  <a href="payment.html" className="btn btn--full margin-right-sm">
                Proceed to checkout
              </a></div>
        </> : null}

      </div>
    </div>
  );
};
