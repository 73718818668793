import React from "react";

import "../styles/general.css";
import "../styles/queries.css";
import "../styles/style.css";
import "../styles/login.css";
import "../styles/prodlist.css";
import "../styles/search.css";

import img1 from "../assets/img/shoes.png";
import img2 from "../assets/img/earphone.png";
import img3 from "../assets/img/watch.png";

const ShopNow = () => {
  return (
    <div className="addprod">
      <h1 className="Heading">Choose the product of your choice</h1>
      <div className="gallerys">
        <div className="cards">
          <img className="displaypicture" alt="" src={img1} />
          <h3 className="prod">Shoes</h3>
          <p className="description">
            Lorem ipsum dolor sit amet, consectetur adipisicing elit.
          </p>
          <h6>$100.00</h6>
          <button className="buy">Add to Cart</button>
        </div>
        <div className="cards">
          <img className="displaypicture" alt="" src={img2} />
          <h3 className="prod">Earphone</h3>
          <p className="description">
            Lorem ipsum dolor sit amet, consectetur adipisicing elit.
          </p>
          <h6>$40.00</h6>
          <button className="buy">Add to Cart</button>
        </div>
        <div className="cards">
          <img className="displaypicture" alt="" src={img3} />
          <h3 className="prod">Watch</h3>
          <p className="description">
            Lorem ipsum dolor sit amet, consectetur adipisicing elit.
          </p>
          <h6>$70.84</h6>
          <button className="buy">Add to Cart</button>
        </div>
      </div>
    </div>
  );
};

export default ShopNow;
