// deleteschooladmin

import React, { useState, useEffect } from "react";
import axios from "axios";
import Header from "./header";
import { baseUrl } from "../utils/script";

import "../styles/general.css";
import "../styles/queries.css";
import "../styles/style.css";
import "../styles/prodlist.css";
import "../styles/search.css";
import "../styles/login.css";
import "../styles/cart.css";

const ManageSchoolAdmin = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    axios
      .get(baseUrl + "managestudent/3")
      .then(function (response) {
        if (response.data) if (response.data.length > 0) setData(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  console.log(data);

  const deleteData = (user) =>{
 // let stud_id = JSON.parse(localStorage.getItem("user")).id;
 axios.post(baseUrl + `deleteschooladmin/${user.id}`)
 .then(function (response) {
 console.log(response.data)
 getData();
 alert("User deleted");
 })
 .catch(function (error) {
   console.log(error);
 })

  }

  return (
    <div>
      <Header />

      <div className="shopping-cart">
        {/* <!-- Title --> */}
        <div className="Heading">Manage School admin</div>

        <>
          <div className="item flex-center">
          <div className="cart-description margin-no">
              <span>Index</span>
            </div>
            <div className="cart-description margin-no">
              <span>Name</span>
            </div>
            <div className="cart-description margin-no">
              <span>Date of Birth</span>
            </div>
            <div className="cart-description margin-no">
              <span>Address</span>
            </div>
            <div className="cart-description margin-no">
              <span>Email</span>
            </div>

            <div className="cart-description margin-no">
              <span>Manage</span>
            </div>
          </div>
        </>

        {/* <!-- Product #1 --> */}
        {data.map((user, index) => (
          <>
            <div className="item flex-center">
            <div className="manage-items margin-no">
                <span>{index +1}</span>
              </div>
              <div className="manage-items margin-no">
                <span>{user.name}</span>
              </div>
              <div className="manage-items margin-no">
                <span>{user.dob}</span>
              </div>
              <div className="manage-items margin-no">
                <span>{user.address}</span>
              </div>
              <div className="manage-items margin-no">
                <span>{user.email}</span>
              </div>
              <div className="manage-items margin-no"><button className="manage-delete-button" onClick={()=>deleteData(user)}>
                Delete
            </button></div>
            </div>
          </>
        ))}
      </div>
    </div>
  );
};

export default ManageSchoolAdmin;
