import React, { useState } from "react";

import "../styles/general.css";
import "../styles/queries.css";
import "../styles/style.css";
import "../styles/prodlist.css";
import "../styles/about.css";
import "../styles/login.css";
import "../styles/search.css";

import Header from "./header";
import { useEffect } from "react";
import axios from "axios";
import { baseUrl } from "../utils/script";

const LeaveClub = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    let stud_id = parseInt(JSON.parse(localStorage.getItem("user")).id);
    axios
      .get(baseUrl + "viewclubcart/" + stud_id)
      .then(function (response) {
        if (response.data)
          if (response.data.length > 0) setData(response.data);
          else setData([]);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const addCart = (user) => {
    let stud_id = JSON.parse(localStorage.getItem("user")).id;
    axios
      .post(baseUrl + `leaveclub/${user.id}/${stud_id}`)
      .then(function (response) {
        console.log(response.data);
        getData();
        alert("Club left succesfully");
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <div>
      <div className="addprod">
        <Header />
        <h1 className="Heading">Club List</h1>
        <div className="gallerys">
          {data.length > 0 ? (
            data.map((user, index) => (
              <>
                <div className="cards">
                  <img className="displaypicture" alt="" src={user.image} />
                  <h3 className="prod">{user.name}</h3>
                  <button className="buy" onClick={() => addCart(user)}>
                    Leave Club
                  </button>
                </div>
              </>
            ))
          ) : (
            <div className="no-items">No clubs registered</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default LeaveClub;
