import React, { useState } from "react";

import "../styles/general.css";
import "../styles/queries.css";
import "../styles/style.css";
import "../styles/prodlist.css";
import "../styles/about.css";
import "../styles/login.css";
import "../styles/search.css";
import Header from "./header";
import { useEffect } from "react";
import axios from "axios";
import { baseUrl } from "../utils/script";

const ProdList = () => {

  const [data, setData] = useState([])

  useEffect(() => {
    getData();
  }, [])
  
  const getData = () =>{
    axios.get(baseUrl + 'displayproduct')
    .then(function (response) {
     if(response.data) if(response.data.length > 0) setData(response.data);
    })
    .catch(function (error) {
      console.log(error);
    })
  }

  const addCart = (user) =>{
    let stud_id = JSON.parse(localStorage.getItem("user")).id;
    axios.post(baseUrl + `addtocart/${user.id}/${stud_id}`, user)
    .then(function (response) {
    console.log(response.data)
    alert("Product added to cart")
    })
    .catch(function (error) {
      console.log(error);
    })

  }

  console.log(data)

  return (
    <div>
      <div className="addprod">
        <Header />
        <h1 className="Heading">Product List</h1>
        <div className="gallerys">  
          {/* <div className="cards">
            <img className="displaypicture" alt="" src={img3} />
            <h3 className="prod">Watch</h3>
            <p className="description">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit.
            </p>
            <h6>$70.84</h6>
            <button className="buy">
              <a className="no-underline" href="cart.html">
                Add to Cart
              </a>
            </button>
          </div> */}

          {
          data.map((user, index)=>(
            <>
            <div className="cards">
            <img className="displaypicture" alt="" src={user.image} />
            <h3 className="prod">{user.name}</h3>
            <p className="description">
              {user.description}
            </p>
            <h6>{user.price}</h6>
            <button className="buy" onClick={()=>addCart(user)}>
              {/* <a className="no-underline" href="cart.html"> */}
                Add to Cart
              {/* </a> */}
            </button>
          </div>
          </>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProdList;
