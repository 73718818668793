//  LeelaMadhav Somu - 1002028333,
//      Dileep Kumar Naidu Ravi - 1002023397,
//      Meet Savani - 10020232888

import React, { useEffect, useState, useRef } from "react";
import "../styles/login.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { baseUrl } from "../utils/script";
import emailjs from "@emailjs/browser";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [random, setRandom] = useState("");
  const [sendOtp, setSendOtp] = useState(true);
  const [otp, setOtp] = useState("");
  const navigate = useNavigate();
  const form = useRef();

  useEffect(() => {
    setRandom(Math.floor(Math.random() * 1000000) + 1);
  }, []);

  console.log(random, otp);

  if (form.current) console.log(form.current.email);

  const sendEmail = () => {
    emailjs
      .send(
        "service_xpivxkh",
        "template_64up4n8",
        {
          email: email,
          password: random,
        },
        "q8D6ygVMWszC_0U6s"
      )
      .then(
        (result) => {
          alert(result.text);
          console.log(result.text);
          setSendOtp(false);
        },
        (error) => {
          alert(error.text);
          console.log(error.text);
        }
      );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (random === parseInt(otp)) {
      axios
        .get(baseUrl + `forgotpassword/${email}`)
        .then(function (response) {
          console.log(response.data);
          // console.log(users)
          if (response.data) alert("The password is:" + response.data[0].password);
          navigate("/index.html");
        });
    } else {
      alert("Incorrect otp");
    }
  };

  const generateRandomNumber = (e) => {
    e.preventDefault();
    sendEmail();
  };

  return (
    <div>
      <div className="addprods">
        <h1 className="Heading">Forgot password</h1>

        {sendOtp ? (
          <>
            <form ref={form} onSubmit={(e) => generateRandomNumber(e)}></form>
            <div className="form-control">
              <label htmlFor="email" id="label-name">
                Email
              </label>
              <input
                type="email"
                id="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter you email"
              />
            </div>

            <button
              className="adsubmit"
              look="flat"
              type="submit"
              value="submit"
              onClick={(e) => generateRandomNumber(e)}
            >
              {/* <a href="home.html" look="flat"> */}
              Send OTP
              {/* </a> */}
            </button>
          </>
        ) : (
          <>
            <form ref={form} onSubmit={(e) => handleSubmit(e)}>
              <div className="form-control">
                <label htmlFor="email" id="label-name">
                  Otp
                </label>
                <input
                  type="text"
                  id="email"
                  name="email"
                  onChange={(e) => setOtp(e.target.value)}
                  placeholder="XXXXXX"
                />
              </div>

              <button
                className="adsubmit"
                look="flat"
                type="submit"
                value="submit"
                onClick={() => handleSubmit()}
              >
                {/* <a href="home.html" look="flat"> */}
                Get Password
                {/* </a> */}
              </button>
            </form>
          </>
        )}

        <div className="login-signup">
          <br />
          <br />
          <br />
          <span className="stext">
            Already a member?
            <a href="index.html" className="text login-link">
              {" "}
              Login Now
            </a>
          </span>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
