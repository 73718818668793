import {
  BrowserRouter as Router,
  Route,
  Link,
  Routes,
  Navigate,
} from "react-router-dom";
import { IonIcon } from "@ionic/react";

// import "./utils/index";
import "./App.css";
// import "../styles/cart.css";
// import "../styles/login.css";
// import "../styles/prodlist.css";
// import "../styles/profile.css";
// import "../styles/general.css";
// import "../styles/queries.css";
// import "../styles/style.css";
// import "../styles/about.css";
// import "../styles/search.css";

import Index from "./components";
import Home from "./components/home";
import Profile from "./components/profile";
import SchoolAdmin from "./components/schooladmin";
import ProdList from "./components/prodlist";
import Searchproduct from "./components/searchproduct";
import Signup from "./components/signup";
import SuperAdmin from "./components/superadmin";
import AboutUs from "./components/aboutus";
import ContactUs from "./components/contactus";
import { Cart } from "./components/cart";
import BusinessAdmin from "./components/businessadmin";
import AddProduct from "./components/addproduct";
import UpdateProduct from "./components/updateproduct";
import Services from "./components/services";
import ShopNow from "./components/shopnow";
import React from "react";
import Payment from "./components/payment";
import DeleteProducts from "./components/deleteproducts";
import ManageStudents from "./components/managestudents";
import ManageBusinessOwner from "./components/managebusinessowner";
import ManageSchoolAdmin from "./components/manageschooladmin";
import ForgotPassword from "./components/forgotpassword";
import Advertisement from "./components/advertisement";
import DeleteAdvertisement from "./components/deleteadvertisement";
import Orders from "./components/orders";
import JoinClub from "./components/joinclub";
import LeaveClub from "./components/leaveclub";
import DeleteClub from "./components/deleteclub";
import ViewClub from "./components/viewclub";
import AddClub from "./components/addclub";
import Reports from "./components/reports";

function App() {
  const role = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user")).role
    : 0;

  return (
    <div>
      <Router>
        <Routes>
          <Route path="/home.html" element={<Home />} />

          <Route path="/aboutus.html" element={<AboutUs />} />
          <Route path="/contactus.html" element={<ContactUs />} />
          <Route path="/prodlist.html" element={<ProdList />} />
          <Route path="/searchproduct.html" element={<Searchproduct />} />
          <Route path="/shopnow.html" element={<ShopNow />} />
          <Route path="/services.html" element={<Services />} />
          <Route path="/index.html" element={<Index />} />
          {role === 0 ? (
            <>
              <Route path="/forgotpassword.html" element={<ForgotPassword />} />
              <Route path="/index.html" element={<Index />} />
              <Route path="/signup.html" element={<Signup />} />
            </>
          ) : null}
          {parseInt(role) === 1 ? (
            <>
              <Route path="/profile.html" element={<Profile />} />
              <Route path="/cart.html" element={<Cart />} />
              <Route path="/payment.html" element={<Payment />} />
              <Route
                path="/advertisement.html"
                element={<DeleteAdvertisement />}
              />
              <Route path="/orders.html" element={<Orders />} />
              <Route path="/joinclub.html" element={<JoinClub />} />
              <Route path="/leaveclub.html" element={<LeaveClub />} />
              <Route path="/viewclub.html" element={<ViewClub />} />
            </>
          ) : null}
          {parseInt(role) === 2 ? (
            <>
              <Route path="/businessadmin.html" element={<BusinessAdmin />} />
              <Route path="/updateproduct.html" element={<UpdateProduct />} />
              <Route path="/addproduct.html" element={<AddProduct />} />
              <Route path="/deleteproduts.html" element={<DeleteProducts />} />
              <Route
                path="/addadvertisement.html"
                element={<Advertisement />}
              />
              <Route
                path="/deleteadvertisement.html"
                element={<DeleteAdvertisement />}
              />
            </>
          ) : null}
          {parseInt(role) === 3 ? (
            <>
              <Route path="/schooladmin.html" element={<SchoolAdmin />} />
              <Route path="/managestudents.html" element={<ManageStudents />} />
              <Route
                path="/managebusinessowner.html"
                element={<ManageBusinessOwner />}
              />
              <Route
                path="/deleteadvertisement.html"
                element={<DeleteAdvertisement />}
              />
              <Route path="/addclub.html" element={<AddClub />} />
              <Route path="/deleteclub.html" element={<DeleteClub />} />
            </>
          ) : null}

          {parseInt(role) === 4 ? (
            <>
              <Route path="/managestudents.html" element={<ManageStudents />} />
              <Route
                path="/managebusinessowner.html"
                element={<ManageBusinessOwner />}
              />
              <Route
                path="/manageschooladmin.html"
                element={<ManageSchoolAdmin />}
              />
              <Route path="/superadmin.html" element={<SuperAdmin />} />
              <Route path="/reports.html" element={<Reports />} />
            </>
          ) : null}

          <Route path="*" element={<Navigate to="/home.html" />} />
        </Routes>
      </Router>
      <a href="https://mxs3288.uta.cloud/chat/login.php" className="chat-icon-float">
        {/* chatbubbles-outline */}
        <IonIcon
          className="icon-mobile-nav white"
          icon="chatBubblesOutline"
          name="chatbubbles-outline"
        ></IonIcon>
      </a>
    </div>
  );
}

export default App;
