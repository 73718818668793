import React, { useState } from "react";

import "../styles/general.css";
import "../styles/queries.css";
import "../styles/style.css";
import "../styles/login.css";
import "../styles/prodlist.css";
import "../styles/search.css";
import Header from "./header";
import axios from "axios";
import { baseUrl } from "../utils/script";

const AddClub = () => {

    const [input, setInput] = useState({});

  const handleChange = (e) => {
    setInput((values) => ({ ...values, [e.target.name]: e.target.value }));
  };

  const handleFileChange = (e) => {
    const name = e.target.name;
    let file = e.target.files[0];
    let reader = new FileReader();
    reader.onloadend = function () {
      setInput((values) => ({ ...values, [name]: reader.result }));
    };
    reader.readAsDataURL(file);
  };

    const handleSubmit = async () => {
    console.log(input);
    axios.post(baseUrl+"addclub", input).then((res) => {
      console.log(res.data);
      if (res.data.status === 1) {
        alert("Club added successfully");
        setInput({});
      } else {
        alert("Record Insertion Unsucessful");
      }
    });
  };

  return (
    <div>
      <Header />
      <div className="addprods">
        <h1 className="Heading">Add Club</h1>
        <div className="form-control">
          <label htmlFor="name" id="label-name">
            Club Name
          </label>
          <input
            type="text"
            id="name"
            name="name"
            onChange={(e) => handleChange(e)}
            placeholder="Enter Name"
          />
        </div>
        {/* <div className="form-control">
          <label htmlFor="text" id="label-name">
            Description
          </label>
          <textarea
            type="text"
            id="text"
            name="text"
            onChange={(e) => handleChange(e)}
            placeholder="Enter Advertisement description"
          />
        </div> */}
        <div className="form-control">
          <label htmlFor="image" id="label-name">
            Image
          </label>
          <input
            type="file"
            id="img"
            name="image"
            onChange={(e) => handleFileChange(e)}
            accept="image/*"
          />
        </div>

        <button className="adsubmit" type="submit" value="submit" onClick={()=>handleSubmit()}>
          Add Club
        </button>
      </div>
    </div>
  )
}

export default AddClub