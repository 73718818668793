import React from "react";

import "../styles/general.css";
import "../styles/queries.css";
import "../styles/style.css";
import "../styles/login.css";
import "../styles/prodlist.css";
import "../styles/search.css";
import Header from "./header";

const UpdateProduct = () => {
  return (
    <div>
      {" "}
      <Header />
      <div className="addprods">
        <h1 className="Heading">Update Product</h1>
        <div className="form-control">
          <label htmlFor="name" id="label-name">
            {" "}
            Product Name{" "}
          </label>
          <input type="text" id="name" placeholder="Enter product name" />
        </div>
        <div className="form-control">
          <label htmlFor="name" id="label-name">
            {" "}
            Description{" "}
          </label>
          <input
            type="text"
            id="name"
            placeholder="Enter product description"
          />
        </div>
        <div className="form-control">
          <label htmlFor="name" id="label-name">
            {" "}
            Price{" "}
          </label>
          <input type="text" id="name" placeholder="Enter product price" />
        </div>
        <div className="form-control">
          <label htmlFor="name" id="label-name">
            {" "}
            Image{" "}
          </label>
          <input type="file" id="img" name="img" accept="image/*" />
        </div>

        <button className="adsubmit" type="submit" value="submit">
          Update Product
        </button>
      </div>
    </div>
  );
};

export default UpdateProduct;
