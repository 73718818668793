import React from "react";
import Header from "./header";
import axios from "axios";
import { baseUrl } from "../utils/script";
const Payment = () => {
  const makePayment = (e) => {
    e.preventDefault();
    let stud_id = parseInt(JSON.parse(localStorage.getItem("user")).id);
    axios
      .post(baseUrl + `soldproduct/${stud_id}`)
      .then(function (response) {
        console.log(response.data);
        alert("Congrulations on the purchase");
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <div>
      <Header />

      <div className="addprods">
        <form  onSubmit={(e) => makePayment(e)}>
          <h1 className="Heading">Payment</h1>
          <div className="form-control">
            <label htmlFor="name" id="label-name">
              Name on Card
            </label>
            <input
              type="text"
              id="name"
              placeholder="Enter name on card"
              required
            />
          </div>
          <div className="form-control">
            <label htmlFor="name" id="label-name">
              Card Number
            </label>
            <input type="text" id="name" placeholder="XXXX-XXXX-XXXX-XXXX" />
          </div>
          <div className="form-control">
            <label htmlFor="name" id="label-name">
              Expiry
            </label>
            <input type="text" id="name" placeholder="MM/YY" />
          </div>
          <div className="form-control">
            <label htmlFor="name" id="label-name">
              CVV
            </label>
            <input type="text" id="name" placeholder="XXX" />
          </div>
          <div className="form-control">
            <label htmlFor="name" id="label-name">
              Billing Address
            </label>
            <input type="text" id="name" placeholder="Enter your address" />
          </div>
          <div className="form-control">
            <label htmlFor="name" id="label-name">
              E-mail
            </label>
            <input type="email" id="name" placeholder="Enter your email" />
          </div>
          <div className="form-control">
            <label htmlFor="name" id="label-name">
              City
            </label>
            <input type="text" id="name" placeholder="Enter your city" />
          </div>
          <div className="form-control">
            <label htmlFor="name" id="label-name">
              Zip code
            </label>
            <input type="number" id="name" placeholder="Enter your zip code" />
          </div>
        <button
          className="adsubmit"
          type="submit"
          value="submit"
          onSubmit={() => makePayment()}
        >
          Make payment
        </button>
        </form>
      </div>
    </div>
  );
};

export default Payment;
