import React, { useEffect } from "react";
import "../styles/general.css";
import img1 from "../assets/img/cover-photo.jpg";
import img2 from "../assets/img/customers/ben.jpg";
import img3 from "../assets/img/customers/hannah.jpg";
import img4 from "../assets/img/pencil.webp";
import "../styles/style.css";
import "../styles/queries.css";

import Header from "./header";

const Home = () => {

  

  return (
    <div>
      <Header />
      <main>
        <section className="section-hero">
          <div className="hero">
            <div className="hero-text-box">
              <h1 className="heading-primary">
              {localStorage.getItem("id")}

                A great product delivered to your door, every single day
              </h1>
              <p className="hero-description">
                A place where you get your daily usage things in resonable price
                and high quality product that make your life as easy as straight
                line.
              </p>
              <a href="prodlist.html" className="btn btn--full margin-right-sm">
                Shop Now
              </a>

              <a href="cart.html" className="btn btn--outline">
                Check Your Cart
              </a>
            </div>
            <div className="hero-img-box">
              <picture>
                {/* <source srcset={img1} type="image/webp">
            <source srcset={img1} type="image/png"> */}

                <img src={img1} className="hero-img" alt="Cover Photo" />
              </picture>
            </div>
          </div>
        </section>

        <section className="section-featureds" id="featureds">
          <div className="featureds-container">
            <span className="subheading">Featured Products</span>
            <h2 className="heading-secondary">
              Once you try it, you can't go back
            </h2>

            <div className="featureds">
              <figure className="featured">
                <img
                  className="featured-img"
                  alt="Photo of customer Ben"
                  src={img2}
                />
                <blockquote className="featured-text">
                  Inexpensive and great products, without even having to order
                  manually! It feels truly magical.
                </blockquote>
                <p className="featured-name">&mdash; Ben</p>
              </figure>

              <figure className="featured">
                <img
                  className="featured-img"
                  alt="Photo of customer Hannah Smith"
                  src={img3}
                />
                <blockquote className="featured-text">
                  I got product for the whole family, and everything is of high
                  quality.
                </blockquote>
                <p className="featured-name">&mdash; Hannah Smith</p>
              </figure>
            </div>
          </div>

          <div className="gallery">
            <figure className="gallery-item">
              <img src={img4} alt="Photo of featured product" />
            </figure>
            <figure className="gallery-item">
              <img src={img4} alt="Photo of featured product" />
            </figure>
            <figure className="gallery-item">
              <img src={img4} alt="Photo of featured product" />
            </figure>
            <figure className="gallery-item">
              <img src={img4} alt="Photo of featured product" />
            </figure>
            <figure className="gallery-item">
              <img src={img4} alt="Photo of featured product" />
            </figure>
            <figure className="gallery-item">
              <img src={img4} alt="Photo of featured product" />
            </figure>
          </div>
        </section>
      </main>
      <footer className="footer">
        <div className="container grid grid--footer">
          <p className="copyright">
            Copyright &copy; <span className="year">2027</span> by University
            Marketplace, Inc. All rights reserved.
          </p>
        </div>
      </footer>
    </div>
  );
};

export default Home;
