import React from "react";
import "../styles/general.css";
import "../styles/queries.css";
import "../styles/style.css";
import "../styles/about.css";
import img2 from "../assets/img/customers/ben.jpg";
import Header from "./header";

const Profile = () => {
  return (
    <div>
      <Header />
      <main className="full-page">
        <section className="section-hero">
          <div className="hero">
            <div className="hero-text-box">
              <h1 className="heading-primary">Meet Savani</h1>
              <p className="hero-description">
                A student buyer
              </p>
              <div className="profile-background-color">
                <a href="prodlist.html" className="btn btn--full margin-right-sm">
                  Buy Products
                </a>
                <a href="orders.html" className="btn btn--full">
                  Return Products
                </a>
              </div>
              <div className="profile-background-color">
                <a href="cart.html" className="btn btn--full margin-right-sm">
                  Cart
                </a>
                {/* <a href="profile.html" className="btn btn--full">
                  Delete Club
                </a> */}
              </div>
              <div className="profile-background-color">
                <a href="advertisement.html" className="btn btn--full margin-right-sm">
                  See Advertisement
                </a>
              </div>
              <div className="profile-background-color">
                <a href="viewclub.html" className="btn btn--full margin-right-sm">
                  View Club
                </a>
              </div>
              <div className="profile-background-color">
                {/* <a href="profile.html" className="btn btn--full">
                  Delete Advertisement
                </a> */}
                    <a href="joinclub.html" className="btn btn--full margin-right-sm">
                  Join Club
                </a>
                <a href="leaveclub.html" className="btn btn--full">
                  Leave Club
                </a>
              </div>
            </div>
            <div className="hero-img-box">
              <picture>
                {/* <source srcset="img/customers/ben.jpg" type="image/webp">
                  <source srcset="img/customers/ben.jpg" type="image/png">
       */}
                <img src={img2} className="hero-img" alt="Cover Photo" />
              </picture>
            </div>
          </div>
        </section>
      </main>
      <footer className="footer">
        <div className="container grid grid--footer">
          <p className="copyright">
            Copyright &copy; <span className="year">2027</span> by University
            Marketplace, Inc. All rights reserved.
          </p>
        </div>
      </footer>
    </div>
  );
};

export default Profile;
