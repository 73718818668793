import React from "react";
import img2 from "../assets/img/cover-photo.jpg";
import "../styles/general.css";
import "../styles/queries.css";
import "../styles/style.css";
import "../styles/about.css";
import Header from "./header";

const AboutUs = () => {
  return (
    <div>
      <Header />

      <main>
        <section className="section-hero">
          <div className="hero">
            <div className="hero-text-box">
              <h1 className="heading-primary">About Us</h1>
              <p className="hero-description">
                A place where you get your daily usage things in resonable price
                and high quality product that make your life as easy as straight
                line.
              </p>
            </div>
            <div className="hero-img-box">
              <picture>
                {/* <source srcset="img/cover-photo.jpg" type="image/webp">
                  <source srcset="img/cover-photo.jpg" type="image/png"> */}

                <img src={img2} className="hero-img" alt="Cover Photo" />
              </picture>
            </div>
          </div>
        </section>

        <div className="about-layout">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d6712.84398861627!2d-97.1139366!3d32.7279836!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x864e7d6dabc9564d%3A0x6c5cbeb084c8b76a!2sUniversity%20of%20Texas%20at%20Arlington!5e0!3m2!1sen!2sus!4v1664929341549!5m2!1sen!2sus"
            width="600"
            height="450"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </main>
      <footer className="footer">
        <div className="container grid grid--footer">
          <p className="copyright">
            Copyright &copy; <span className="year">2027</span> by University
            Marketplace, Inc. All rights reserved.
          </p>
        </div>
      </footer>
    </div>
  );
};

export default AboutUs;
