//  LeelaMadhav Somu - 1002028333,
//      Dileep Kumar Naidu Ravi - 1002023397,
//      Meet Savani - 10020232888

import React, { useEffect, useState } from "react";
import "../styles/login.css";
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { baseUrl } from "../utils/script";

const Index = () => {

  const [input, setInput] = useState({})
  
  const navigate = useNavigate();

const login = () =>{
    axios.post(baseUrl + 'login',input)
    .then(function (response) {
      console.log(response.data);
      if(response.data.length > 0){
      localStorage.setItem("user", JSON.stringify(response.data[0]));
      navigate("/home.html")
      } else {
        alert("Wrong email and password")
      }
    })
    .catch(function (error) {
      console.log(error);
    })
}

const handleChange = (e) =>{
  setInput({...input, [e.target.name]: e.target.value});
}

  return (
    <div>
      <div className="addprods">
        <h1 className="Heading">Login</h1>
        <div className="form-control">
          <label htmlFor="email" id="label-name">
            Email
          </label>
          <input type="text" id="email" name="email" onChange={(e)=>handleChange(e)} placeholder="Enter you email" />
        </div>
        <div className="form-control">
          <label htmlFor="password" id="label-name">
            Password
          </label>
          <input type="password" id="password" name="password" onChange={(e)=>handleChange(e)} placeholder="Enter your password" />
        </div>

        <button className="adsubmit" look="flat" type="submit" value="submit" onClick={()=>login()}>
          {/* <a href="home.html" look="flat"> */}
            Login
          {/* </a> */}
        </button>
        <div className="login-signup">
        <span className="stext">
            Forgot password?
            <a href="forgotpassword.html" className="text login-link">
              {" "}
              Click here
            </a>
          </span>
          <br />
          <br />
          <br />
          <span className="stext">
            Already a member?
            <a href="signup.html" className="text login-link">
              {" "}
              Sign Up Now
            </a>
          </span>
        </div>
      </div>
    </div>
  );
};

export default Index;
